import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PosterContent = ({ postId }) => {
    const [projectData, setProjectData] = useState([]);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const getUsers = async (data) => {
        try {
            const response = await axios.get(
                `https://sociity.in/sociityApi/emp/read.php?search_query=${data}`
            );
            const projectSingle = response.data.result;
            setProjectData(projectSingle);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getUsers(postId);
    }, [postId]);

    const validateForm = () => {
        if (!fname || !lname || !companyname || !email || !phone || !message) {
            toast.error('All fields are required!');
            return false;
        }
        return true;
    };

    const uploadMessage = async () => {
        if (!validateForm()) return;

        let fData = new FormData();
        fData.append('fname', fname);
        fData.append('lname', lname);
        fData.append('companyname', companyname);
        fData.append('email', email);
        fData.append('phone', phone);
        fData.append('message', message);
        fData.append('title', projectData[0]?.title);

        try {
            //const response = await axios.post('http://localhost/sociityApi/uploadnew.php', fData, {
            const response = await axios.post('https://sociity.in/sociityApi/uploadnew.php', fData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.message === 'Message has been sent!') {
                toast.success("Your message has been sent!", {
                    autoClose: 10000,
                    position: toast.POSITION.TOP_RIGHT
                });
                // Clear form fields after successful submission
                setFname('');
                setLname('');
                setCompanyname('');
                setEmail('');
                setPhone('');
                setMessage('');
            } else {
                toast.error('Message could not be sent!', {
                    autoClose: 10000,
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            toast.error('An error occurred while sending your message.', {
                autoClose: 10000,
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await uploadMessage();
    };
    return (
        <>
            <section className="posterSection01" >
                <div className="container posterContainer">
                    <div className="row" >
                        <div className="col-lg-6">
                            <div className="abThumb" >
                                <img src={projectData[0]?.posterimage} alt="poster" style={{ padding: "5px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="abCon1p">
                                <div className="subTitlep" style={{ textAlign: "center" }}>{projectData[0]?.title}</div>
                                <div className="contact_form contactPageForm">
                                    <h2 className="secTitle02" style={{ alignItems: "center" }}>Get in touch</h2>
                                    <div className="col-lg-12 col-md-12">

                                    <form onSubmit={handleSubmit}>
                                        <input required className="required" type="text" name="fname" id="fname" value={fname} onChange={(e) => setFname(e.target.value)} placeholder="First Name" />
                                        <input required className="required" type="text" name="lname" id='lname' value={lname} onChange={(e) => setLname(e.target.value)} placeholder="Last Name" />
                                        <input required className="required" type="text" name="companyname" id='companyname' value={companyname} onChange={(e) => setCompanyname(e.target.value)} placeholder="Company Name" />
                                        <input required className="required" type="email" name="email" id='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Business Email Address" />
                                        <input required className="required" type="tel" name="phone" id='phone' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
                                        <textarea required className="required" name="message" id='message' value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Any additional information you would like to share with us"></textarea>
                                        <button type="submit" className="berpo_btn" name='send' value="SEND">Request for detailed proposal</button>
                                    </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}
export default PosterContent;